<template>
  <b-card>
    <h2 class="text-primary">Video URL</h2>
    <!-- media -->
   
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
            <b-col sm="12" cols="6" class="mt-75">
        
           <b-badge variant="primary">
           {{ optionsLocal.lessonType }}
             </b-badge>
      
        </b-col>
        <b-col sm="12" class="mt-75">
          <b-form-group label="Video URL" label-for="course-title">
            <b-form-input
              v-model="optionsLocal.videoUrl"
              placeholder="https://vimeo.com"
              name="title"
            />
          </b-form-group>
        </b-col>
        
      



      
       

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="sendInformation"
          >
            Save changes
          </b-button>
 
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BBadge
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    BBadge
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
    };
  },
  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.optionsLocal.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },

    authorChanged(payload) {
      console.log(payload);
      this.optionsLocal.ClientInstructors = Array.isArray(payload)
        ? payload
        : [payload];

      console.log(this.optionsLocal);
    },

      courseModeUpdated(payload) {
      console.log("Catefy result in info ");
      console.log(payload);
      this.optionsLocal.coursemode = payload.name;
    },

    courseCategoryUpdated(payload) {
      console.log("Catefy result in info ");
      console.log(payload);
      this.optionsLocal.CourseCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },

    sendInformation() {
      console.log(this.optionsLocal);
      console.log("update lesson  video  is launching with id : " + this.courseId);

     
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatelessondetail/${this.courseId}`,
        data: {
          info: "videourl",
          file: null,
          content: this.optionsLocal,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {},
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
