<template>
  <b-card>
    <h2 class="text-primary mb-2">Pdf Lesson</h2>
    <!-- media -->
  
   <h6 class="section-label mt-2   mb-2">PDF File</h6>
    <b-media no-body>
  
      <div v-if="!Boolean(optionsLocal.assetUrl)">
      <b-media-body class="mt-50 ml-50">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
         
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".pdf"
          :hidden="true"
          plain
          @input="fileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>Allowed PDF File only. Max size of 2 MB</b-card-text>
      </b-media-body>


      </div>
        <div v-if="Boolean(optionsLocal.assetUrl)">
      <b-media-body class="mt-50 ml-50">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
           @click="deleteFile()"
        >
          Delete File
        </b-button>
        
       
      </b-media-body>


      </div>
     
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2" v-if="Boolean(optionsLocal.assetUrl)" >
      <b-row>
        
         <pdf :src="optionsLocal.assetUrl"></pdf>
      
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BBadge,
  BTable
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import CategorySelector from "../../course-category/components/CategorySelector.vue";
import AuthorSelector from "../../instructor/AuthorSelector.vue";
import moment from 'moment'
import pdf from 'vue-pdf'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    CategorySelector,
    AuthorSelector,
    BBadge,
    BTable,pdf

  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
    courseId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localOptions: {},
      isLoading: false,
      file: null,
      isUpdated: false,
      assetInfo:{
        title:null,
        assetUrl:null,
        order:0,
        lessonid:0

      },
      optionsLocal: JSON.parse(JSON.stringify(this.generalData.data)),
      profileFile: null,
      fields: [
        'id',
        'title',
       
        'assetUrl',
        
        'createdAt',
      ],
      
    };
  },
  methods: {
       moment: function () {
    return moment();
  },
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    sendInformation() {
      console.log(this.assetInfo);
      console.log("update lesson  is launching with id : " + this.courseId);
     console.log(this.courseId);
     this.assetInfo.lessonid=  this.optionsLocal.id;

   var lessonid=this.optionsLocal.id;
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatelessondetail/${lessonid}`,
        data: {
          info: "pdfdocument",
          file: null,
          content: this.assetInfo,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          console.log(r);

          this.optionsLocal.assetUrl=this.assetInfo.assetUrl;
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
      deleteFile() {
      console.log(this.assetInfo);
      console.log("update lesson  is launching with id : " + this.courseId);
     console.log(this.courseId);
     this.assetInfo.lessonid=  this.optionsLocal.id;

   var lessonid=this.optionsLocal.id;
      this.isLoading = true;
      axios({
        method: "put",
        url: `/lms/admin/course/updatelessondetail/${lessonid}`,
        data: {
          info: "pdfdocumentdelete",
          file: null,
          content: this.assetInfo,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          console.log(r);

          this.optionsLocal.assetUrl=null;
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
        formData.append("lessonId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);

          

          this.assetInfo.assetUrl = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
           this.sendInformation() ;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },
 
 
 

  
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
  },
  mounted() {
      console.log(this.generalData.data);

  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
